import React from 'react';
import { Link } from 'gatsby';
import { getPathLevels } from '../../utils';

import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({ location, backgroundColor, customLevels }) => {
  const levels = customLevels || getPathLevels(location.pathname);

  return (
    <div
      className={styles.wrapper}
      style={backgroundColor ? { backgroundColor } : undefined}
    >
      <div className={styles.container}>
        {levels.map((level, index) => (
          <span key={`breadcrumb-${level.path}`} className={styles.link}>
            {index > 0 && (
              <img
                alt="Chevron Right Icon"
                src={require('../../images/icon-back.svg')}
              />
            )}
            <Link to={level.path}>{level.title}</Link>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumb;
