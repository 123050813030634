//Core
import axios from 'axios';
import { CONTENTFUL_PATH } from '../../constants';
//Local
import {
  GET_TOP_PAGE_ASYNC,
  SET_TOP_PAGE,
  GET_FANS_PAGE_ASYNC,
  SET_FANS_PAGE,
  GET_PRESS_PAGE_ASYNC,
  SET_PRESS_PAGE,
  GET_HR_PAGE_ASYNC,
  SET_HR_PAGE,
  GET_WORK_LIFE_TOP_PAGE_ASYNC,
  SET_WORK_LIFE_TOP_PAGE,
  GET_ENGINEERING_PAGE_ASYNC,
  SET_ENGINEERING_PAGE,
  GET_FANS_ARTICLES_ASYNC,
  SET_FANS_ARTICLES,
  GET_PRESS_ARTICLES_ASYNC,
  SET_PRESS_ARTICLES,
  GET_ENGINEERING_ARTICLES_ASYNC,
  SET_ENGINEERING_ARTICLES,
  GET_HR_ARTICLES_ASYNC,
  SET_HR_ARTICLES,
  GET_RECOMMENDED_ARTICLES_ASYNC,
  SET_RECOMMENDED_ARTICLES,
} from './types';

//Sync
export const setTopPage = (payload) => ({
  type: SET_TOP_PAGE,
  payload,
});

export const setFansPage = (payload) => ({
  type: SET_FANS_PAGE,
  payload,
});

export const setPressPage = (payload) => ({
  type: SET_PRESS_PAGE,
  payload,
});

export const setWorkLifeTopPage = (payload) => ({
  type: SET_WORK_LIFE_TOP_PAGE,
  payload,
});

export const setHrPage = (payload) => ({
  type: SET_HR_PAGE,
  payload,
});

export const setEngineeringPage = (payload) => ({
  type: SET_ENGINEERING_PAGE,
  payload,
});

export const setFansArticles = (payload) => ({
  type: SET_FANS_ARTICLES,
  payload,
});

export const setPressArticles = (payload) => ({
  type: SET_PRESS_ARTICLES,
  payload,
});

export const setHrArticles = (payload) => ({
  type: SET_HR_ARTICLES,
  payload,
});

export const setEngineeringArticles = (payload) => ({
  type: SET_ENGINEERING_ARTICLES,
  payload,
});

export const setReccomendedArticles = (payload) => ({
  type: SET_RECOMMENDED_ARTICLES,
  payload,
});

//Async
export const getTopPageAsync = () => async (dispatch) => {
  dispatch({ type: GET_TOP_PAGE_ASYNC });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}media-center-top.json`);

    dispatch(setTopPage(data.data[0].fields));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getFansPageAsync = () => async (dispatch) => {
  dispatch({ type: GET_FANS_PAGE_ASYNC });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}media-center-fans.json`);

    dispatch(setFansPage(data.data[0].fields));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getPressPageAsync = () => async (dispatch) => {
  dispatch({ type: GET_PRESS_PAGE_ASYNC });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}media-center-press.json`);

    dispatch(setPressPage(data.data[0].fields));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getWorkLifeTopPageAsync = () => async (dispatch) => {
  dispatch({ type: GET_WORK_LIFE_TOP_PAGE_ASYNC });

  try {
    const data = await axios.get(
      `${CONTENTFUL_PATH}media-center-work-life-top.json`
    );

    dispatch(setWorkLifeTopPage(data.data[0].fields));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getHrPageAsync = () => async (dispatch) => {
  dispatch({ type: GET_HR_PAGE_ASYNC });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}media-center-hr.json`);

    dispatch(setHrPage(data.data[0].fields));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getEngineeringPageAsync = () => async (dispatch) => {
  dispatch({ type: GET_ENGINEERING_PAGE_ASYNC });

  try {
    const data = await axios.get(
      `${CONTENTFUL_PATH}media-center-engineering.json`
    );

    dispatch(setEngineeringPage(data.data[0].fields));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getFansArticlesAsync = () => async (dispatch) => {
  dispatch({ type: GET_FANS_ARTICLES_ASYNC });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}fans-articles.json`);

    dispatch(setFansArticles(data.data));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getPressArticlesAsync = () => async (dispatch) => {
  dispatch({ type: GET_PRESS_ARTICLES_ASYNC });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}press-articles.json`);

    dispatch(setPressArticles(data.data));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getHrArticlesAsync = () => async (dispatch) => {
  dispatch({ type: GET_HR_ARTICLES_ASYNC });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}hr-articles.json`);

    dispatch(setHrArticles(data.data));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getEngineeringArticlesAsync = () => async (dispatch) => {
  dispatch({ type: GET_ENGINEERING_ARTICLES_ASYNC });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}engineering-articles.json`);

    dispatch(setEngineeringArticles(data.data));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getReccomendedArticlesAsync = () => async (dispatch) => {
  dispatch({ type: GET_RECOMMENDED_ARTICLES_ASYNC });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}recommended-articles.json`);

    dispatch(setReccomendedArticles(data.data[0].fields.articles));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};
